import { useState, useEffect, useRef } from 'react';
import { bool, string } from 'prop-types';
import { Modal, Spin } from 'antd';

import RecommendedBag from 'components/recommendedBag';
import { formatCurrency, getCookie, getMarket, COOKIES, eraseCookie, nnormalSizesMap, getFixedPrice, obtainVoucherPrices } from 'utils/helpers';

import { IS_CAMPER, IS_NNORMAL } from 'utils/constants/system';
import { FAMILIES } from 'utils/constants/products';
import { TARGET_SIZE_LABEL_MARKETS } from 'utils/constants/sizes';
import { defaultLocale, getCountryFromMarket } from 'utils/translations';
import { ProgressiveImg } from 'components/base';
import { getCart } from 'actions/products';
import { CloseModalSVG, CloseSVG } from 'components/iconsSVG/close';
import { dataLayerHandleEvent } from 'utils/dataLayers';
import { parseBagEcommerceGTM, parseBagItemsEcommerceGA4 } from 'utils/gtmUtils';

import { useUser } from 'context/user';
import { useI18n } from 'context/i18n';
import useDelayedState from 'hooks/useDelayedState';
import { useIsMounted, useLink } from 'hooks';
import { canUserHover } from 'utils';

import styles from './style.module.css';

export function MiniBag({ locale, isInSalePath }) {
  const {
    t,
    profileData,
    setBagLength,
    bag,
    setBag,
    setBagHasMemberOnlyProduct,
    setRecommendedProductsForBag,
    promoLabel,
    setPromoLabel,
    promoLabelType,
    setPromoLabelType,
  } = useI18n();
  const { contextFilters, activatedPrevouchers } = useUser;
  const isMounted = useIsMounted();
  const to = useLink();
  // const [ bag, setBag ] = useState(null);
  const [visible, setVisible] = useDelayedState(false);
  const [loading, setLoading] = useState(false);
  const { emptyBagCollections, config } = profileData;
  const bagRef = useRef(null);
  const timer = useRef();
  const previousBagLength = useRef(undefined);
  const initialLoad = useRef(true);

  const showFreeShipping = config?.show_free_shipping || false;
  const showGiftPurchase = config?.show_gift_purchase || false;
  const showProducts = bag && bag.length > 0;
  const market = getMarket(locale);
  const country = getCountryFromMarket(market);
  const total = bag && bag.length > 0 ? JSON.parse(JSON.stringify(bag)).reduce((accumulator, current) => accumulator + current.price.current, 0) : 0;
  const currency = bag && bag.length > 0 ? formatCurrency(bag[0].price.currency) : '';
  const onlyOneItem = bag && bag.length === 1;

  const handleOnClickRemoveBtn = async (itemIndex) => {
    setLoading(true);
    const cookiesBagId = getCookie(COOKIES.BAG);
    const employeeId = getCookie(COOKIES.EMPLOYEE_ID) || '';
    const voucher = getCookie(COOKIES.BONDS) || '';
    const zipCode = getCookie(COOKIES.ZIP) || '';
    const newBag = JSON.parse(JSON.stringify(bag));
    const itemToDelete = newBag[itemIndex];
    newBag.splice(itemIndex, 1);
    const newBagFormatted = JSON.parse(JSON.stringify(newBag)).map((product) => ({
      code: product.productId,
      ...product,
    }));
    const tax = zipCode && zipCode !== '' ? { zipcode: zipCode } : {};

    const { productId } = itemToDelete;
    const hasSomeOfTheSameID = newBag.some((item) => item.productId === productId);

    if (!hasSomeOfTheSameID) {
      setRecommendedProductsForBag((prev) =>
        prev.filter((recommendedItem) => recommendedItem.originalProductId !== productId).map((item, i) => ({ ...item, order: i })),
      );
    }

    try {
      let updatedPromoLabel = null;
      const result = await getCart({
        lang: locale,
        products: newBagFormatted,
        bondValue: voucher,
        tax,
        hashOrderId: cookiesBagId,
        employeeId,
      });
      if (result) {
        dataLayerHandleEvent({
          event: 'eventoEC',
          eventCat: 'ecommerce',
          eventAct: 'removeFromCart',
          eventLbl: itemToDelete.productId,
          eventValue: itemToDelete.price.current,
          ecommerce: {
            currencyCode: itemToDelete.price.currency,
            remove: {
              products: parseBagEcommerceGTM([itemToDelete]),
            },
          },
        });
        dataLayerHandleEvent({
          event: 'remove_from_cart',
          ecommerce: {
            currency: itemToDelete?.price?.currency,
            value: itemToDelete?.price?.current,
            items: parseBagItemsEcommerceGA4([itemToDelete], itemIndex + 1),
          },
          cart: {
            items: parseBagItemsEcommerceGA4(newBag),
          },
        });
        let updatedPromoLabelType = 'progress';
        if (showFreeShipping) {
          updatedPromoLabel = result?.freeShippingLabel || null;
        }
        if (showGiftPurchase) {
          updatedPromoLabel = result?.gwplabel || result?.gwplabelObtained || result?.freeShippingLabel || null;
          if (result?.gwplabelObtained && result?.gwplabelObtained !== '') {
            updatedPromoLabel = result?.gwplabelObtained;
            updatedPromoLabelType = 'obtained';
          }
        }
        const newShipments = result.productShipmentCost.shipmentsCosts;
        const isEmptyBag = newShipments.length === 0 || result.productShipmentCost.products.length === 0;
        if (newBag.length === 0 || isEmptyBag) {
          eraseCookie(COOKIES.BAG);
          eraseCookie(COOKIES.BAG_DATE);
        }
        setBagLength(isEmptyBag ? 0 : newBag.length);
        const hasMemberOnlyProduct = isEmptyBag ? false : newBag.filter((product) => product.membersOnly === true).length > 0;
        setBagHasMemberOnlyProduct(hasMemberOnlyProduct);
        setBag(isEmptyBag ? [] : newBag);
        setPromoLabel(updatedPromoLabel);
        setPromoLabelType(updatedPromoLabelType);
        setLoading(false);
      }
    } catch (error) {
      // handle error
      setLoading(false);
      Modal.error({
        title: t('bolsa', 'error.borrar.producto.titulo', 'An error has occurred'),
        content: t('bolsa', 'error.borrar.producto.descripcion', 'We are sorry. The product could not be removed.'),
      });
    }
  };

  useEffect(() => {
    if (window !== undefined) {
      window.showMiniBag = () => {
        setVisible(true);
      };
    }
  }, []);

  useEffect(() => {
    const showBagIfItemAdded = () => {
      if (bagRef.current?.offsetParent === null) return;
      if (initialLoad.current) return;
      if (previousBagLength.current === undefined) {
        return;
      }
      if (previousBagLength.current >= bag.length) {
        return;
      }
      setVisible(true);
      timer.current = setTimeout(() => {
        if (isMounted()) {
          setVisible(false);
        }
      }, 6000);
    };

    showBagIfItemAdded();
    if (bag) {
      initialLoad.current = false;
      previousBagLength.current = bag.length;
    }

    return () => {
      if (timer.current) clearTimeout(timer.current);
    };
  }, [bag]);

  const sunneiProducts = bag?.filter((item) => item.sunnei === true);
  const hasOnlyOneSunnei = sunneiProducts?.length === 1;

  const handleClickMiniBagIcon = () => {
    const canHover = canUserHover();
    if (!canHover) {
      setVisible((prev) => !prev);
      return;
    }

    if (canHover && bag && bag.length > 0) {
      window.location.assign(to('/bolsa'));
    }
  };

  const handleMouseEnter = () => {
    const canHover = canUserHover();
    if (!canHover) return;
    clearTimeout(timer.current);
    setVisible(true);
  };

  const handleMouseLeave = () => {
    const canHover = canUserHover();
    if (!canHover) return;
    setVisible(false, 300);
  };

  // Si un usuario interactua con la minibolsa en movil quitar el timeout para que no se cierre.
  const handleInteraction = () => {
    clearTimeout(timer.current);
  };

  return (
    <nav ref={bagRef} className={styles.bagIcon} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <button onClick={handleClickMiniBagIcon} className={bag && bag.length > 0 ? styles.bagLink : `${styles.bagLink} ${styles.emptyBag}`}>
        {bag && <span className={styles.bagItemsCount}>{!isInSalePath && bag.length > 0 ? bag.length : ''}</span>}
      </button>
      {visible && !isInSalePath ?
        <div className={bag && bag.length > 0 ? styles.bagMenu : `${styles.bagMenu} ${styles.emptyMenu}`} onPointerDown={handleInteraction}>
          {showProducts && (
            <div className={styles.bagOverlay}>
              <div className="mr-4 flex items-center justify-between gap-4 border-b border-divider pb-[5px]">
                <p className={`${styles.menuItemTitle} mb-0 text-xs uppercase nnormal:font-secondary`}>{t('menu.superior', 'mi.bolsa')}</p>
                <button className="flex items-center justify-center" onClick={() => setVisible(false)}>
                  <CloseModalSVG className="size-4" />
                </button>
              </div>
              <Spin spinning={loading}>
                <ul className={styles.bagList}>
                  <div className={`${styles.itemsWrapper} ${onlyOneItem ? styles.noScroll : ''}`}>
                    {bag.map((product, index) => {
                      let parsedTarget = 'M';
                      if (market === 'US') {
                        parsedTarget = 'US';
                      } else if (['M', 'W'].includes(product.target)) {
                        parsedTarget = product.target;
                      }
                      let sizeLabel = null;
                      if (IS_CAMPER) {
                        const useSizeEquivalence =
                          product.size &&
                          product.sizeLabel &&
                          product.size !== product.sizeLabel &&
                          [FAMILIES.SHOES, FAMILIES.ACCESSORIES].includes(Number(product.family));
                        sizeLabel =
                          useSizeEquivalence && !TARGET_SIZE_LABEL_MARKETS.includes(market) ?
                            <span className={styles.size}>{`${product.sizeLabel} US / ${product.size} EU`}</span>
                          : <span className={styles.size}>
                              {product.camperLabPan === true ?
                                'US'
                              : product.sizeLabel || product.size ?
                                country
                              : ''}{' '}
                              {TARGET_SIZE_LABEL_MARKETS.includes(market) && product.family && Number(product.family) === FAMILIES.SHOES ?
                                `${product.gender ?? product.target} ${product.sizeLabel || product.size}`
                              : product.sizeLabel || product.size}
                            </span>;
                        console.log(product.sizeLabel, product.size, country);
                      }

                      const fixedPrice = getFixedPrice(market, profileData, product?.price?.current);
                      const voucherPriceInfo = obtainVoucherPrices(
                        { ...product, prices: product.price },
                        t,
                        locale,
                        contextFilters,
                        activatedPrevouchers,
                        false,
                        true,
                      );
                      let info = {};
                      if (voucherPriceInfo) {
                        info = voucherPriceInfo.info || {};
                      }
                      return (
                        <li data-ge-basket-cartitemid={product.productId} key={`${product.productId}-${index}`} className={styles.productItem}>
                          <div className={styles.menuItem}>
                            <a href={product.url}>
                              <ProgressiveImg
                                src={product.thumbnail}
                                alt={`bag image for ${product.productId} ${index}`}
                                otherProps={{
                                  className: styles.productImg,
                                  width: 90,
                                  height: 90,
                                  iscamperone: product.camperoneOrderId !== null && product.camperoneOrderId !== undefined,
                                }}
                              />
                            </a>
                            <div className={styles.productInfo}>
                              <p className={styles.productInfoName}>{product.name}</p>
                              <p className={IS_NNORMAL ? styles.productDescription : undefined}>
                                {IS_NNORMAL ? product.subtitleH1 : product.productLabel || product.productId}
                              </p>
                              <p>
                                {IS_NNORMAL ?
                                  <span className={styles.productDescription}>{nnormalSizesMap?.[parsedTarget]?.[product.size] ?? product.size}</span>
                                : sizeLabel}
                              </p>
                              <p className={styles.productInfoPrice}>
                                {product.price.previous && product.price.previous > product.price.current && (
                                  <strike data-ge-basket-productlistprice="data-ge-basket-productlistprice" className={styles.itemDiscount}>
                                    {product.price.previousFormated || `${product.price.previous} ${formatCurrency(product.price.currency)}`}
                                  </strike>
                                )}
                                {product.price.discount && product.price.discount > 0 && (
                                  <span
                                    className={`${product.price.discountFromVoucher ? `${styles.itemDiscount} ${styles.discountByVoucher}` : styles.itemDiscount} green`}
                                  >{`-${product.price.discount}%`}</span>
                                )}
                                <span data-fp={fixedPrice} data-ge-price="data-ge-price" data-ge-basket-productsaleprice="data-ge-basket-productsaleprice">
                                  {product.price.currentFormated || `${product.price.current} ${formatCurrency(product.price.currency)}`}
                                </span>
                              </p>
                              {info?.appliedLabel ?
                                <span className={`${styles.productInfoPrice} ${styles.discountByVoucher}`}>{info?.appliedLabel}</span>
                              : null}
                            </div>
                            <div className={styles.removeProduct} onClick={() => handleOnClickRemoveBtn(index)}>
                              <CloseSVG />
                            </div>
                          </div>
                        </li>
                      );
                    })}
                    {/* {IS_NNORMAL && total >= GIFT_MIN_TOTAL_PRICE && renderGifHat &&
                <li data-ge-basket-cartitemid={'N1ARC02-001'} key={'gift-free-cap'} className={styles.productItem}>
                  <div className={styles.menuItem}>
                    <picture>
                      <img
                        src={'/assets-new/nnormal/N1ARC02-001_L.jpg' }
                        alt={'Image of gift Bag photo'}
                        width={80}
                        height={80}
                      />
                    </picture>
                    <div className={styles.productInfo}>
                      <p className={styles.productInfoName}>{t('menu.derecha', 'free.cap.item.price', '***Limited-edition tote bag')}</p>
                      <p>
                        <span data-ge-basket-productsaleprice="data-ge-basket-productsaleprice" >{t('menu.derecha', 'free.cap.item.name', '***FREE')}</span>
                      </p>
                    </div>
                    <div className={styles.removeProduct}></div>
                  </div>
                </li>
              } */}
                  </div>
                  <li className={styles.btnPurchase} key="gotobag" id="bag-purchase-btn">
                    <div className={styles.purchaseWrapper}>
                      {hasOnlyOneSunnei && <p className="error" dangerouslySetInnerHTML={{ __html: t('bolsa', 'completa.sunnei') }} />}
                      {promoLabel && <p className={promoLabelType === 'obtained' ? styles.promoLabelObtained : styles.promoLabel}>{promoLabel}</p>}
                      <p className={styles.totalPrice}>
                        <span>{'Total'}</span>
                        <span data-fp={getFixedPrice(market, profileData, total)} data-ge-basket-totals="data-ge-basket-totals">{`${total} ${currency}`}</span>
                      </p>
                      <p className={styles.taxes}>
                        {IS_CAMPER && market !== 'US' && market !== 'CA' && <span> {t('mis.pedidos', 'impuestos.incluidos', 'All taxes included')}</span>}
                        {profileData.freeShipping && profileData.freeShipping === true && profileData.freeShippingOver && profileData.freeShippingOver < total ?
                          <span className={styles.taxesPrices}>{t('mis.pedidos', 'free.shipping.included', 'Free Shipping')}</span>
                        : null}
                      </p>
                      <a href={`/${locale}/bolsa`} className={styles.btnGoPurchase}>
                        <p>{`${t('menu.derecha', 'derecha.comprar')} (${bag.length})`}</p>
                      </a>
                    </div>
                  </li>
                  <RecommendedBag />
                </ul>
              </Spin>
            </div>
          )}
          {!showProducts && (
            <div className={styles.bagOverlay}>
              <p>{t('menu.derecha', 'derecha.bolsa.vacia')}</p>
              <p>{t('menu.derecha', 'take.look.collection', '***Take a look at our collection.')}</p>
              <div className={styles.buttonContainer}>
                {emptyBagCollections?.map((item) => (
                  <a key={`collectionButton-${item.title}`} href={item.url} className={styles.collectionButton}>
                    {item.title}
                  </a>
                ))}
              </div>
            </div>
          )}
        </div>
      : null}
    </nav>
  );
}

MiniBag.propTypes = {
  locale: string,
  isInSalePath: bool,
};

MiniBag.defaultProps = {
  locale: defaultLocale,
  isInSalePath: false,
};

MiniBag.displayName = 'MiniBag';
